import { Box, Button, Card, Chip, Divider, Grid, Icon, Paper, Stack, Typography } from '@mui/material';
import { Funnel, Truck } from '@phosphor-icons/react';
import kebabCase from 'lodash/kebabCase'
import startCase from 'lodash/startCase';
import { AddressInterface } from 'modules/addresses/interfaces';
import { TableColumnInterface } from 'modules/common/components';
import { ResetButton } from 'modules/common/components/reset-button/reset-button.component';
import { TableSearch } from 'modules/common/components/table-search/table-search.component';
import { DeliveriesTable } from 'modules/deliveries/components/deliveries-table/deliveries-table.component';
import { DeliveryFilterOverlay } from 'modules/deliveries/components/delivery-filter-overlay';
import { useDeliveryTableSectionStyles } from 'modules/deliveries/components/delivery-table-section/delivery-table-section.styles';
import { DeliveryTableStateInterface, useDeliveries } from 'modules/deliveries/hooks/use-deliveries.hook';
import { DeliveryInterface } from 'modules/deliveries/interfaces/delivery.interface';
import {
  removeDeliveryIncoterms,
  removeDeliveryOrderType,
  removeDeliveryProduct,
  removeDeliveryShipToAddress,
  removeDeliveryStatus,
  removeTransportation,
  resetDeliveryFilter,
  setDeliveryIncotermsFilter,
  setDeliveryOrdertype,
  setDeliveryProductSearchByFilter,
  setDeliverySearchById,
  setDeliveryShipToAddressFilter,
  setDeliveryStatusesFilter,
  setTransportationFilter,
} from 'modules/filter-by/filter-by.slice';
import { useFilterBy } from 'modules/filter-by/hooks';
import { IncotermsInterface } from 'modules/forms/components/incoterms-autocomplete-search/interfaces/incoterms.interface';
import { ProductAutocompleteOptionInterface } from 'modules/forms/components/product-autocomplete-search';
import { OptionInterface } from 'modules/forms/components/select/select.component';
import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { OrderTypeEnum } from 'views/orders/enums';

interface DeliverySectionPartialInterface {
  title?: string;
  showHeader?: boolean;
  deliveryTableStateInterface?: Partial<DeliveryTableStateInterface>;
  showFilter?: boolean;
  showSearch?: boolean;
  paginationEnabled?: boolean;
  viewAllHandler?: () => void;
  filterColumns?: (columns: TableColumnInterface<DeliveryInterface>[]) => TableColumnInterface<DeliveryInterface>[];
}

export const DeliveryTableSection: FunctionComponent<DeliverySectionPartialInterface> = (props) => {
  const {
    showFilter = true,
    showSearch = true,
    deliveryTableStateInterface,
    showHeader = true,
    paginationEnabled = true,
    title,
    filterColumns,
    viewAllHandler,
  } = props;
  const dispatch = useDispatch();

  const classes = useDeliveryTableSectionStyles();
  const { deliveryState } = useFilterBy();
  const { products, shipToAddresses, transportation, incoterms, statuses, searchById, orderType } = deliveryState;
  const [searchContent, setSearchContent] = useState(searchById || '');
  const [openFilterOverlay, setOpenFilterOverlay] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<ProductAutocompleteOptionInterface[]>(products);
  const [selectedShipToAddresses, setSelectedShipToAddresses] = useState<AddressInterface[]>(shipToAddresses);
  const [selectedTransportation, setSelectedTransportation] = useState<OptionInterface[]>(transportation);
  const [selectedIncoterms, setSelectedIncoterms] = useState<IncotermsInterface[]>(incoterms);
  const [selectedStatuses, setSelectedStatuses] = useState<OptionInterface[]>(statuses);
  const [selectedOrderType, setSelectedOrderType] = useState<OptionInterface<OrderTypeEnum>[]>(orderType || []);

  const {
    data,
    order,
    totalCount,
    pageSize,
    isLoading,
    pageNumber,
    handlePageChange,
    handleOrderChange,
    handleSearchText,
    reload,
    filter,
  } = useDeliveries({ deliveryTableStateInterface });

  const handleContractSearchIdChange = (text: string) => {
    if (!text) {
      dispatch(setDeliverySearchById(''))
      reload();
    }
    setSearchContent(text);
  };

  const handleShipToAddressesChange = (value) => {
    setSelectedShipToAddresses(value);
  };
  const handleTransportationChange = (value) => {
    setSelectedTransportation(value);
  };

  const handleProductChange = (value) => {
    setSelectedProduct(value);
  };

  const handleIncotermsChange = (value: IncotermsInterface[]) => {
    setSelectedIncoterms(value);
  };

  const handleStatusChange = (value) => {
    setSelectedStatuses(value);
  };

  const handleOrderTypeChange = (value) => {
    setSelectedOrderType(value);
  }

  const handleApplyFilter = () => {
    dispatch(setDeliveryProductSearchByFilter({ product: selectedProduct }));
    dispatch(setDeliveryShipToAddressFilter(selectedShipToAddresses));
    dispatch(setTransportationFilter(selectedTransportation));
    dispatch(setDeliveryIncotermsFilter(selectedIncoterms));
    dispatch(setDeliveryStatusesFilter(selectedStatuses));
    dispatch(setDeliveryOrdertype(selectedOrderType));
    if (!selectedOrderType?.length && searchById) {
      dispatch(setDeliverySearchById(''))
    }
  };

  const handleResetFilter = () => {
    setSelectedProduct([]);
    setSelectedShipToAddresses([]);
    setSelectedTransportation([]);
    setSelectedIncoterms([]);
    setSelectedStatuses([])
    dispatch(resetDeliveryFilter());
  };

  useEffect(() => {
    setSelectedProduct(products || []);
    setSelectedShipToAddresses(shipToAddresses || []);
    setSelectedTransportation(transportation || []);
    setSelectedIncoterms(incoterms || []);
    setSelectedStatuses(statuses || [])
    setSelectedOrderType(orderType || [])
    setSearchContent(searchById)
  }, [products, shipToAddresses, transportation, incoterms, statuses, orderType, searchById]);

  return (
    <>
      {showSearch && (
        <Grid container xs={12} md={8} marginBottom={'14px'}>
          <TableSearch
            placeholder="Search for Customer PO Number, OrderId, HELM Delivery ID or Contract ID"
            title="Search for Customer PO Number, OrderId, HELM Delivery ID or Contract ID"
            searchText={searchContent}
            onSearchTextChange={handleContractSearchIdChange}
            onTextSearch={() => handleSearchText(searchContent?.trim())}
          />
        </Grid>
      )}
      <Paper className={classes.paper}>
        <Card>
          {showFilter && (
            <Box className={classes.filterBox}>
              <div className={classes.filterBoxChips}>
                {products?.map((product) => (
                  <div key={product?.id} className={classes.customChip}>
                    <Chip
                      size="small"
                      variant="filled"
                      label={`Product: ${product?.label}`}
                      onDelete={() => {
                        dispatch(removeDeliveryProduct(product));
                      }}
                    />
                  </div>
                ))}

                {shipToAddresses?.map((address) => (
                  <div key={address?.id} className={classes.customChip}>
                    <Chip
                      size="small"
                      variant="filled"
                      label={`Ship to: ${address?.displayAddress}`}
                      onDelete={() => {
                        dispatch(removeDeliveryShipToAddress(address));
                      }}
                    />
                  </div>
                ))}
                {transportation?.map((mode, index) => (
                  <div key={index} className={classes.customChip}>
                    <Chip
                      size="small"
                      variant="filled"
                      label={`Mode: ${mode?.label}`}
                      onDelete={() => {
                        dispatch(removeTransportation(mode));
                      }}
                    />
                  </div>
                ))}
                {incoterms?.map((incoterm, index) => (
                  <div key={index} className={classes.customChip}>
                    <Chip
                      size="small"
                      variant="filled"
                      label={`Incoterm: ${incoterm?.name}`}
                      onDelete={() => {
                        dispatch(removeDeliveryIncoterms(incoterm));
                      }}
                    />
                  </div>
                ))}
                {statuses?.map((status, index) => (
                  <div key={index} className={classes.customChip}>
                    <Chip
                      size="small"
                      variant="filled"
                      label={`Status: ${status.label}`}
                      onDelete={() => {
                        dispatch(removeDeliveryStatus(status));
                      }}
                    />
                  </div>
                ))}
                {orderType?.map((type, index) => (
                  <div key={index} className={classes.customChip}>
                    <Chip
                      size="small"
                      variant="filled"
                      label={`OrderType: ${startCase(kebabCase(type.value))}`}
                      onDelete={() => {
                        dispatch(removeDeliveryOrderType(type));
                      }}
                    />
                  </div>
                ))}
              </div>

              <div className={classes.filterBoxButtonContainer}>
                {Boolean(products?.length || shipToAddresses?.length || transportation?.length || statuses?.length || orderType?.length) && (
                  <ResetButton onClickHandler={handleResetFilter} />
                )}
                <Button
                  variant="filterButton"
                  color="primary"
                  onClick={() => {
                    setOpenFilterOverlay(true);
                  }}
                >
                  <Funnel size={20} />
                  Filter &nbsp;
                </Button>
              </div>
            </Box>
          )}
          {showHeader && (
            <Grid container alignItems={'center'} justifyContent="space-between" padding={'16px'}>
              <Stack direction={'row'} spacing={1.3} alignItems={'center'}>
                <Icon>
                  <Truck size={24} color="#a0d7d0" />
                </Icon>
                <Typography className={classes.cardHeaderHeading} fontSize={'18px !important'} variant='semiBold'>{title || 'Latest Deliveries'}</Typography>
              </Stack>
              <Stack direction={'row'}>
                <Button onClick={viewAllHandler} variant="text" color="primary">
                  <Typography
                    color={'primary'}
                    fontSize={14}
                    variant='semiBold'
                    fontWeight={800}
                    letterSpacing={0.46}
                  >{`View All`}</Typography>
                </Button>
              </Stack>
            </Grid>
          )}
          <Divider variant="fullWidth" sx={{ width: '100%' }} />

          <DeliveriesTable
            {...{
              filterColumns,
              data: data || [],
              order,
              totalCount,
              pageSize,
              isLoading,
              pageNumber,
              handlePageChange,
              handleOrderChange,
              handleSearchText,
              reload,
              filter,
              paginationEnabled,
            }}
          />
        </Card>
        <DeliveryFilterOverlay
          selectedStatus={selectedStatuses}
          handleStatusChange={handleStatusChange}
          selectedProduct={selectedProduct}
          selectedShipToAddresses={selectedShipToAddresses}
          selectedTransportation={selectedTransportation}
          handleProductChange={handleProductChange}
          handleTransportationChange={handleTransportationChange}
          handleShipToAddressesChange={handleShipToAddressesChange}
          handleApplyFilter={handleApplyFilter}
          handleResetFilter={handleResetFilter}
          open={openFilterOverlay}
          selectedIncoterms={selectedIncoterms}
          handleOrderTypeChange={handleOrderTypeChange}
          selectedOrderType={selectedOrderType}
          handleIncotermsChange={handleIncotermsChange}
          onClose={() => {
            setOpenFilterOverlay(false);
          }}
          onSuccess={() => {
            setOpenFilterOverlay(false);
          }}
        />
      </Paper>
    </>
  );
};
