import { NoPermission } from 'modules/acl/components/no-permission';
import { useHasPermission } from 'modules/acl/hooks';
import { ComponentType } from 'react';


export const withAcl = ({ permission }: { permission: string }) => <Props,>(Component: ComponentType<Props>): ComponentType<Props> => {

  const WithPermission = (props: Props) => {
    const { isLoading, authorized } = useHasPermission(permission);
    return isLoading || authorized == null ? null : authorized ? <Component {...props} /> : <NoPermission />
  }
  return WithPermission;
}