import { OrderEnum } from 'modules/common/enums';
import { useAsyncOperation } from 'modules/common/hooks';
import { useCurrentBusinessPartnerInternal } from 'modules/current-business-partner-selector/hooks';
import { OrderDeliveriesOrderSortEnum } from 'modules/deliveries/enums/order-deliveries-order-sort.enum';
import { useFetchDeliveries } from 'modules/deliveries/hooks/use-fetch-deliveries.hook';
import { DeliveryInterface, DeliveryStatusEnum } from 'modules/deliveries/interfaces/delivery.interface';
import { setDeliveryTableState } from 'modules/filter-by/filter-by.slice';
import { useFilterBy } from 'modules/filter-by/hooks';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { OrderTypeEnum } from 'views/orders/enums';

export interface DeliveryTableStateInterface {
  pageNumber: number;
  pageSize: number;
  order?: {
    sort: OrderDeliveriesOrderSortEnum;
    order: OrderEnum;
  };
  filter?: {
    orderId?: string;
    orderTypes?: OrderTypeEnum[];
    contractId?: string;
    productId?: string;
    kunnr?: string;
    searchText?: string;
    shipToAddress?: string[];
  };
}

interface DeliveryHookOptionsInterface {
  deliveryTableStateInterface?: Partial<DeliveryTableStateInterface>;
}
export interface DeliveryTableFilterInterface {
  contractId?: string;
  searchText?: string;
  productIds?: string[];
  shipToAddress?: string[];
  transportationMode?: string[];
  incoterms?: string[];
  statuses?: DeliveryStatusEnum[];
  orderTypes?: OrderTypeEnum[]
}

export interface UseDeliveryHookResponseInterface extends DeliveryTableStateInterface {
  data: DeliveryInterface[];
  totalCount: number;
  isLoading: boolean;
  handlePageChange: (newPage: number, pageSize: number) => void;
  handlePageRowsCountChange?: (rowsCount: number) => void;
  handleOrderChange: (sort: OrderDeliveriesOrderSortEnum, order: OrderEnum) => void;
  handleSearchText?: (text: string) => void;
  reload?: () => void;
}

export const useDeliveries = (options: DeliveryHookOptionsInterface): UseDeliveryHookResponseInterface => {
  const deliveryTableStateInterface = options?.deliveryTableStateInterface;

  const { fetchDeliveries } = useFetchDeliveries();
  const { isLoading, initiateOperation, status } = useAsyncOperation({ callback: fetchDeliveries });
  const dispatch = useDispatch();
  const { currentBusinessPartnerInternal } = useCurrentBusinessPartnerInternal();
  const { deliveryState } = useFilterBy();
  const {
    tableState: deliveryTableState,
  } = deliveryState;

  const [tableState, setTableState] = useState<DeliveryTableStateInterface>({
    pageNumber: 0,
    pageSize: deliveryTableState?.rowPerPage,
    filter: { kunnr: '' },
    order: {
      order: OrderEnum.DESC,
      sort: OrderDeliveriesOrderSortEnum.ID,
    },
    ...(!!deliveryTableStateInterface && deliveryTableStateInterface),
  });

  useEffect(() => {
    if (deliveryTableStateInterface) {
      setTableState({
        ...tableState,
        ...deliveryTableStateInterface,
      });
    }
  }, [deliveryTableStateInterface]);

  const totalCount = status?.result?.totalCount;
  const data = status?.result?.data;

  const { pageNumber, pageSize, order, filter } = tableState;

  useEffect(() => {
    if (currentBusinessPartnerInternal) {
      // if state filters to be applied
      void initiateOperation({
        offset: pageNumber * pageSize,
        limit: pageSize,
        order,
        filter: {
          ...filter,
          kunnr: currentBusinessPartnerInternal?.kunnr,
        },
      });
    }
  }, [
    pageNumber,
    currentBusinessPartnerInternal,
    pageSize,
    order,
    filter,
  ]);

  const reload = useCallback(() => {
    setTableState((ts) => ({
      ...ts,
      pageNumber: 0,
      pageSize: 20,
      order: { sort: OrderDeliveriesOrderSortEnum.ID, order: OrderEnum.DESC },
      filter: { kunnr: currentBusinessPartnerInternal.kunnr },
      ...(!!deliveryTableStateInterface && deliveryTableStateInterface),
    }));
  }, [setTableState, currentBusinessPartnerInternal]);

  const handleOrderChange = useCallback(
    (sort, orderDirection) => {
      setTableState((ts) => ({
        ...ts,
        order: {
          sort,
          order: orderDirection,
        },
      }));
    },
    [setTableState],
  );

  const handlePageRowsCountChange = useCallback(
    (newPageSize: number) => {
      setTableState((ts) => ({
        ...ts,
        pageSize: newPageSize,
      }));
    },
    [setTableState],
  );

  const handleSearchText = useCallback(
    (searchText: string) => {
      setTableState((ts) => ({
        ...ts,
        pageNumber: 0,
        filter: { ...filter, searchText },
      }));
    },
    [setTableState],
  );

  const handlePageChange = useCallback(
    (newPageNumber: number, newPageSize: number) => {
      dispatch(setDeliveryTableState({ rowPerPage: newPageSize }));
      setTableState((ts) => ({
        ...ts,
        pageNumber: newPageNumber,
        pageSize: newPageSize,
      }));
    },
    [setTableState, dispatch],
  );

  return {
    ...tableState,
    handlePageChange,
    handlePageRowsCountChange,
    handleSearchText,
    handleOrderChange,
    isLoading,
    reload,
    data,
    totalCount,
  };
};
