import { gql } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import { requestGql } from 'modules/common/utils/request-gql';
import { IncotermsDataInterface } from 'modules/forms/components/incoterms-autocomplete-search/interfaces/incoterms-data.interface';
import { IncotermsQueryInterface } from 'modules/forms/components/incoterms-autocomplete-search/interfaces/incoterms-query.interface';

export interface UseFetchIntercomsHookInterface {
  fetchIncoterms: (query: IncotermsQueryInterface) => Promise<IncotermsDataInterface>;
}

export const useFetchIncoterms = (): UseFetchIntercomsHookInterface => {
  const fetchIncoterms = (query: IncotermsQueryInterface): Promise<IncotermsDataInterface> =>
    requestGql(
      {
        query: gql`
          query Incoterms($limit: Float, $offset: Float, $order: IncotermsOrderArgType, $kunnr: String!) {
            incoterms(limit: $limit, offset: $offset, order: $order, kunnr: $kunnr) {
              data {
                kunnr
                name
              }

              totalCount
            }
          }
        `,
        variables: !isEmpty(query)
          ? query
          : {},
      },
      null,
      'incoterms',
    );
  return {
    fetchIncoterms,
  };
};
