import { Button, Card, Divider, Grid, Paper } from '@mui/material';
import lowerCase from 'lodash/lowerCase';
import startCase from 'lodash/startCase';
import { ActionOverlay } from 'modules/action-overlay/components/action-overlay';
import { DeliveryTransportationEnum } from 'modules/deliveries/enums';
import { DeliveryStatusEnum } from 'modules/deliveries/interfaces/delivery.interface';
import { DeliveryFilterInterface } from 'modules/deliveries/interfaces/delivery-filter.interface';
import { ProductAutocompleteSearchMulti } from 'modules/forms/components';
import {
  AddressSearchMulti,
} from 'modules/forms/components/address-search-multi/address-search-multi.component';
import { IncotermsAutoCompleteSearchComponent } from 'modules/forms/components/incoterms-autocomplete-search/incoterms-autocomplete-search.component';
import { SelectComponent } from 'modules/forms/components/select/select.component';
import { FunctionComponent } from 'react';

// const orderTypeOptions = [
//   {
//     label: 'Call off',
//     value: OrderTypeEnum.CALL_OFF,
//   },
//   {
//     label: 'Spot',
//     value: OrderTypeEnum.SPOT,
//   },
// ];

export const DeliveryFilterOverlay: FunctionComponent<DeliveryFilterInterface> = (props) => {
  const {
    selectedProduct,
    selectedShipToAddresses,
    selectedTransportation,
    selectedStatus,
    handleStatusChange,
    handleTransportationChange,
    handleProductChange,
    handleApplyFilter,
    handleShipToAddressesChange,
    handleResetFilter,
    handleIncotermsChange,
    selectedIncoterms,
    onClose,
  } = props;

  const transportationOptions = [
    {
      label: 'Road Packed',
      value: DeliveryTransportationEnum.ROAD_PACKED,
    },
    {
      label: 'Road Bulk',
      value: DeliveryTransportationEnum.ROAD_BULK,
    },
    {
      label: 'Ocean Container',
      value: DeliveryTransportationEnum.OCEAN_CONTAINER,
    },
    {
      label: 'Ocean Charter',
      value: DeliveryTransportationEnum.OCEAN_CHARTER,
    },
    {
      label: 'Air',
      value: DeliveryTransportationEnum.AIR,
    },
    {
      label: 'Rail',
      value: DeliveryTransportationEnum.RAIL,
    },
    {
      label: 'Pipeline',
      value: DeliveryTransportationEnum.PIPELINE,
    },
  ];

  const deliveryStatusOptions = Object.keys(DeliveryStatusEnum).map((item) => ({
    label: startCase(lowerCase(item)),
    value: item,
  }))

  return (
    <Paper elevation={0}>
      <Card>
        <ActionOverlay
          config={{
            title: 'Filter',
            size: 'small',
          }}
          {...props}
        >
          <Grid container p={'24px'} gap={'24px'}>
            <Grid item xs={12}>
              <ProductAutocompleteSearchMulti
                label={'Product'}
                value={selectedProduct}
                onChange={handleProductChange}
                placeholder="All Products"
              />
            </Grid>
            <Grid item xs={12}>
              <AddressSearchMulti value={selectedShipToAddresses} onChange={handleShipToAddressesChange} />
            </Grid>


            <Grid item xs={12}>
              <IncotermsAutoCompleteSearchComponent
                onChange={handleIncotermsChange}
                value={selectedIncoterms}
                disabled={false}
                label='Incoterms'
                multiple={true}
                placeholder='All'
              />
            </Grid>

            {/* Not displaying Order Type component for now. */}
            {/* <Grid item xs={12}>
              <SelectComponent
                label={'Order Types'}
                placeholder="All Order Types "
                options={orderTypeOptions}
                value={selectedOrderType || []}
                onChange={handleOrderTypeChange}
              />
            </Grid> */}

            <Grid item xs={12}>
              <SelectComponent
                label={'Transportation Mode'}
                placeholder="All Transportation Mode"
                options={transportationOptions}
                value={selectedTransportation}
                onChange={handleTransportationChange}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectComponent
                label={'Status'}
                placeholder="All Statuses"
                options={deliveryStatusOptions}
                value={selectedStatus}
                onChange={handleStatusChange}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid container p={'24px'} gap={'12px'}>
            <Grid item xs={12}>
              <Button
                variant="contained"
                fullWidth
                size="large"
                type="button"
                color="primary"
                onClick={() => {
                  void handleApplyFilter();
                  void onClose();
                }}
              >
                Apply
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                fullWidth
                size="large"
                type="button"
                color="secondary"
                onClick={() => {
                  void handleResetFilter();
                  void onClose();
                }}
              >
                Reset all filters
              </Button>
            </Grid>
          </Grid>
        </ActionOverlay>
      </Card>
    </Paper>
  );
};