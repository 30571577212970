import { useAsyncOperation } from 'modules/common/hooks';
import { useCurrentBusinessPartnerInternal } from 'modules/current-business-partner-selector/hooks';
import { useFetchIncoterms } from 'modules/forms/components/incoterms-autocomplete-search/hooks/use-fetch-incoterms.hook'
import { IncotermsHookData } from 'modules/forms/components/incoterms-autocomplete-search/interfaces/incoterms-hook-data.interface';
import { IncotermsQueryInterface } from 'modules/forms/components/incoterms-autocomplete-search/interfaces/incoterms-query.interface';
import { useEffect, useState } from 'react';


export const useIncotermsHook = (query: Partial<IncotermsQueryInterface>): IncotermsHookData => {
  const { fetchIncoterms } = useFetchIncoterms();
  const { status, isLoading, initiateOperation } = useAsyncOperation({ callback: fetchIncoterms });
  const { currentBusinessPartnerInternal } = useCurrentBusinessPartnerInternal()
  const [queryState, setQueryState] = useState<IncotermsQueryInterface>({
    ...query,
    kunnr: query?.kunnr || currentBusinessPartnerInternal?.kunnr
  });
  const options = status?.result;

  useEffect(() => {
    setQueryState({ ...queryState, ...query });
  }, [query])

  useEffect(() => {
    if (queryState && (queryState?.kunnr || currentBusinessPartnerInternal?.kunnr)) {
      const queryParam = { ...queryState, kunnr: query?.kunnr || currentBusinessPartnerInternal?.kunnr }
      void initiateOperation(queryParam)
    }
  }, [queryState, currentBusinessPartnerInternal?.kunnr])

  return {
    options,
    isLoading
  }
}