import { Autocomplete, Checkbox, CircularProgress, InputLabel, TextField } from '@mui/material';
import { AutocompleteTag } from 'modules/common/components/autocomplete-tag/autocomplete-tag.component';
import { CustomLabel } from 'modules/common/components/custom-label/custom-label.component';
import { Loader } from 'modules/common/components/loader';
import { useIncotermsHook } from 'modules/forms/components/incoterms-autocomplete-search/hooks/use-incoterms.hook'
import { IncotermsInterface } from 'modules/forms/components/incoterms-autocomplete-search/interfaces/incoterms.interface';
import { IntercomsAutoCompletePropsInterface } from 'modules/forms/components/incoterms-autocomplete-search/interfaces/incoterms-autocomplete-search.interface';
import { IncotermsDataInterface } from 'modules/forms/components/incoterms-autocomplete-search/interfaces/incoterms-data.interface';
import React, { useEffect, useState } from 'react';

export const IncotermsAutoCompleteSearchComponent = (props: IntercomsAutoCompletePropsInterface): React.ReactElement => {
  const { label = 'Search Product', query, disabled, value, placeholder, onChange, multiple } = props;
  const { options: incotermsOptions, isLoading } = useIncotermsHook(query);
  const [options, setOptions] = useState<readonly IncotermsInterface[]>([]);

  useEffect(() => {
    setOptions(incotermsOptions?.data)
  }, [incotermsOptions])

  return (
    <>
      <CustomLabel value={label} />
      <Autocomplete
        disablePortal
        disabled={disabled}
        multiple={!!multiple}
        sx={{
          '& .MuiFormControl-root': {
            margin: 0
          }
        }}
        options={options || []}
        renderTags={(tagValue: IncotermsDataInterface["data"], getTagProps) =>
          tagValue.map((option, index) => (
            <AutocompleteTag key={index} index={index} getTagProps={getTagProps} label={option.name}
            />
          ))
        }
        onChange={(_: React.SyntheticEvent, newValue) => {
          onChange(newValue as IncotermsDataInterface["data"]);
        }}
        disableCloseOnSelect={true}
        value={value}
        loadingText={<Loader />}
        loading={isLoading}
        getOptionLabel={(option: IncotermsInterface) => `${option?.name}`}
        isOptionEqualToValue={(
          option: IncotermsInterface,
          valueCheck: IncotermsInterface,
        ) => option.name === valueCheck.name}
        renderOption={(renderOptionProps, option: IncotermsInterface, { selected }) => {
          const liProps = {
            ...renderOptionProps,
            ...{ key: option?.name || 1 },
          };

          return (
            <li {...liProps}>
              <Checkbox style={{ marginRight: 8 }} checked={selected} />
              {option?.name}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            sx={{ marginTop: 1, marginBottom: 1 }}
            {...params}
            size="small"
            variant="filled"
            placeholder={!value?.length && !disabled ? placeholder : ''}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? <CircularProgress color="inherit" size={16} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </>
  );
}