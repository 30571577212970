import { useHasPermission } from 'modules/acl/hooks';
import { LoadingSkeleton } from 'modules/common/components';
import { ReactElement } from 'react';

interface IAclProviderInterface {
  children: ReactElement;
  permission: string;
  fallbackComponent?: () => ReactElement;
}

export const AclProvider = (props: IAclProviderInterface): ReactElement => {
  const { permission, children, fallbackComponent: FallbackComponent } = props;
  const { isLoading, authorized } = useHasPermission(permission);

  return isLoading || authorized == null ? (
    <LoadingSkeleton />
  ) : authorized ? (
    children
  ) : FallbackComponent ? (
    <FallbackComponent />
  ) : null;
};
