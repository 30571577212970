
export interface DeliveryInterface {
  id?: string;
  productId?: string;
  productName?: string;
  customerReference?: string;
  orderId?: string;
  contractId?: string;
  shipTo?: string[];
  soldTo?: string[];
  billTo?: string[];
  actualDeliveryDate?: string;
  plannedDeliveryDate?: string;
  transportationMode?: string;
  transportationModeText?: string;
  incoterms?: string;
  incotermsLocation?: string;
  deliveredVolume?: string;
  deliveredVolumeUnit?: string;
  status?: DeliveryStatusEnum;
  createdByEmailAddress?: string;
  createdByUsername?: string;
}

export enum DeliveryStatusEnum {
  CONFIRMED = 'CONFIRMED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  INVOICED = 'INVOICED',
}