import { roqMakeStyles } from 'modules/common/utils';

export const useTableSearchStyles = roqMakeStyles((theme) => ({
  containerBox: {
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  searchTextField: {
    maxWidth: '100%',
    margin: 0,
  },
  searchInput: {
    backgroundColor: '#ecf0f3',
  },
  searchButton: {
    height: 43,
    lineHeight: '0px !important',
    '& .MuiButton-startIcon': {
      [theme.breakpoints.down('md')]: {
        margin: '0px !important'
      }
    }
  },
}));
