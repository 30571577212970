import { gql } from '@apollo/client';
import { OrderEnum } from 'modules/common/enums';
import { requestGql } from 'modules/common/utils/request-gql';
import { OrderDeliveriesOrderSortEnum } from 'modules/deliveries/enums/order-deliveries-order-sort.enum';
import { FetchDeliveryInterface } from 'modules/deliveries/interfaces/fetch-delivery.interface';

export interface FetchDeliveriesArgsInterface {
  filter: {
    contractId?: string;
    productId?: string;
    orderId?: string;
    kunnr: string;
    searchText?: string;
    incoterms?: string[];
  }
  offset?: number;
  limit?: number;
  order?: {
    order: OrderEnum;
    sort: OrderDeliveriesOrderSortEnum
  };
}

export interface UseFetchDeliveriesHookInterface {
  fetchDeliveries: (query?: FetchDeliveriesArgsInterface) => Promise<FetchDeliveryInterface>;
}

export const useFetchDeliveries = (): UseFetchDeliveriesHookInterface => {

  const fetchDeliveries = async (args: FetchDeliveriesArgsInterface): Promise<FetchDeliveryInterface> => requestGql(
    {
      query: gql`
          query FetchDeliveries(
            $filter: FetchDeliveriesFilterArgType
            $limit: Int
            $offset: Int
            $order: DeliveryOrderArgType
            ) {
            fetchDeliveries(
              filter: $filter, 
              limit: $limit, 
              offset: $offset, 
              order: $order
            ) {
              data {
                id
                productId
                productName
                customerReference
                status
                shipTo
                actualDeliveryDate
                plannedDeliveryDate
                transportationMode
                transportationModeText
                incoterms
                incotermsLocation
                deliveredVolume
                deliveredVolumeUnit
                createdByEmailAddress
              }
              totalCount
            }
          }
        `,
      variables: args,
    },
    null,
    'fetchDeliveries',
  )

  return {
    fetchDeliveries,
  };
};
