import { Box, Button, Grid, IconButton, InputAdornment, SxProps, TextField, Theme } from '@mui/material';
import { MagnifyingGlass, X } from '@phosphor-icons/react';
import { CustomTooltip } from 'modules/common/components/custom-tooltip/custom-tooltip.component';
import { useTableSearchStyles } from 'modules/common/components/table-search/table-search.styles';
import { useWidth } from 'modules/common/hooks';
import { useCurrentBusinessPartnerInternal } from 'modules/current-business-partner-selector/hooks';
import { useUserRoles } from 'modules/users/hooks';
import React, { FunctionComponent, useRef} from 'react';

interface TableSearchInterface {
  onSearchTextChange: (text: string) => void;
  onTextSearch: () => void;
  placeholder?: string;
  searchText?: string;
  title?: string;
  textFieldSxProps?: SxProps<Theme>;
}

export const TableSearch: FunctionComponent<TableSearchInterface> = ({
  onSearchTextChange,
  onTextSearch,
  searchText,
  placeholder = 'Search',
  textFieldSxProps = {},
  title = 'Search',
}: TableSearchInterface) => {
  const classes = useTableSearchStyles();
  const { currentBusinessPartnerInternal, isLoading } = useCurrentBusinessPartnerInternal();
  const { isCustomer } = useUserRoles();
  const screenWidth = useWidth();  
  const tableSearchRef = useRef(null);
  const tableSearchWidth = tableSearchRef.current?.offsetWidth;
  const isRemoveButtonText = tableSearchWidth <= 300;
  const disabled = isLoading || (isCustomer && !currentBusinessPartnerInternal?.id);

  return (
    <Grid ref={tableSearchRef} style={{ display: 'flex', flexDirection: 'row', flexBasis: '672px', minWidth: '290px', maxWidth: '672px', alignItems: 'center' }} xs={12}>
      <Grid item xs={9}>
        <CustomTooltip placement="top" arrow title={title}>
          <TextField
            sx={{
              ...textFieldSxProps,
              verticalAlign: 'sub',
              '& .MuiFilledInput-root': {
                minHeight: '38px'
              }
            }}
            inputProps={{
              className: classes.searchInput,
              fontSize: '16px',
              lineHeight: '26px',
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'
                  onClick={() =>
                    onSearchTextChange('')
                  }
                >
                  {
                    searchText ?
                      <IconButton
                        sx={{
                          cursor: 'pointer'
                        }}
                      >
                        <X />
                      </IconButton>
                      :
                      null
                  }
                </InputAdornment>
              )
            }}
            variant="filled"
            placeholder={placeholder}
            fullWidth
            className={classes.searchTextField}
            onChange={(evt) => onSearchTextChange(evt.target.value)}
            onKeyUp={(evt) => {
              if (evt.key === 'Enter') {
                onTextSearch();
              }
            }}
            value={searchText}
            type="text"
            disabled={disabled}
          />

        </CustomTooltip>
      </Grid>
      <Grid marginLeft={'12px'}>
        <Box maxHeight={'min-content'}>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            startIcon={<MagnifyingGlass size={20} />}
            className={classes.searchButton}
            onClick={onTextSearch}
            disabled={disabled}
            sx={{
              alignItems: 'center',
              border: 'solid 2px secondary'
            }}
          >
            {/xs|sm/.test(screenWidth) || isRemoveButtonText ? '' : 'Search'}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};
