import { Theme } from '@mui/material';
import { roqMakeStyles } from 'modules/common/utils';

export const useDeliveriesTableStyles = roqMakeStyles((theme: Theme) => ({
  "th": {
    width: "200px"
  },
  links: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
}));
