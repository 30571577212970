import {
  Autocomplete,
  Checkbox,
  CircularProgress,
  FilledInputProps,
  InputLabel,
  SxProps,
  TextField,
  Theme,
} from '@mui/material';
import find from 'lodash/find';
import isString from 'lodash/isString';
import { useAddressSearch } from 'modules/addresses/hooks/use-address-search.hook';
import { AutocompleteTag } from 'modules/common/components/autocomplete-tag/autocomplete-tag.component';
import { CustomLabel } from 'modules/common/components/custom-label/custom-label.component';
import { Loader } from 'modules/common/components/loader';
import { useCurrentBusinessPartnerInternal } from 'modules/current-business-partner-selector/hooks';
import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OrderTypeEnum } from 'views/orders/enums';

export interface AddressOptionInterface {
  id: string;
  displayAddress: string;
}

interface AddressSearchMultiInterface {
  onChange?: (value: AddressOptionInterface[]) => void;
  value?: string[] | AddressOptionInterface[];
  label?: string;
  contractId?: string;
  productId?: string;
  orderTypes?: OrderTypeEnum[];
  displayLabel?: boolean;
  currentBusinessPartnerkunnr?: string;
  chipSystemProps?: SxProps<Theme>;
  inputProps?: Partial<FilledInputProps>;
  inputStyle?: SxProps;
  labelStyle?: SxProps;
}

export const AddressSearchMulti = ({
  onChange,
  value,
  label,
  orderTypes,
  contractId,
  productId,
  displayLabel = true,
  chipSystemProps,
  currentBusinessPartnerkunnr,
  inputProps,
  inputStyle = {},
  labelStyle
}: AddressSearchMultiInterface): ReactElement => {
  const { t } = useTranslation();
  const { currentBusinessPartnerInternal } = useCurrentBusinessPartnerInternal();
  const [autoCompleteValue, setAutoCompleteValue] = useState<string[] | AddressOptionInterface[]>();
  const { isLoading, data } = useAddressSearch({
    kunnr: currentBusinessPartnerkunnr || currentBusinessPartnerInternal?.kunnr,
    orderTypes,
    contractId,
    productId,
  });
  const [options, setOptions] = useState<AddressOptionInterface[]>([]);

  useEffect(() => {
    const result = (value as unknown[])?.filter((it) => isString(it))?.length === (value as unknown[])?.length;
    if (data && !!result) {
      const updatedValues = value.map((id) => {
        const res = data.find((d) => d.id === id);
        if (res) {
          return res;
        }
        return id;
      });
      setAutoCompleteValue(updatedValues);
    } else {
      setAutoCompleteValue(value);
    }
  }, [value, data]);

  useEffect(() => {
    setOptions(data);
  }, [data]);

  return (
    <>
      {displayLabel && <CustomLabel styleProps={labelStyle} value={label || t('addressSearch.shipToAddress')} />}
      {currentBusinessPartnerInternal?.kunnr && (
        <Autocomplete
          multiple
          sx={{
            ...inputStyle,
            ...({
              '& .MuiFormControl-root': {
                margin: 0
              }
            })
          }}
          id="address-search"
          style={{ width: '100%' }}
          options={options || []}
          loading={isLoading}
          value={autoCompleteValue}
          getOptionLabel={(option: AddressOptionInterface) => option.displayAddress}
          filterSelectedOptions={false}
          disableCloseOnSelect={true}
          filterOptions={(o) => o}
          isOptionEqualToValue={(option: AddressOptionInterface, valueCheck: AddressOptionInterface) => option.id === valueCheck?.id}
          noOptionsText={t('addressSearch.noAddressFound')}
          loadingText={<Loader />}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="filled"
              size="small"
              placeholder={!value?.length ? t('addressSearch.allAddresses') : ''}
              InputProps={{
                ...params.InputProps,
                ...(!!inputProps && inputProps),
                endAdornment: (
                  <>
                    {isLoading ? <CircularProgress color="inherit" size={16} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          renderTags={(tagValue: AddressOptionInterface[], getTagProps) => {
            const filteredTags = tagValue.filter((t) => Boolean(find(data, { id: t.id })));
            return filteredTags.map((option, index) => (
              <AutocompleteTag
                chipSystemProps={chipSystemProps}
                key={index}
                index={index}
                getTagProps={getTagProps}
                label={option.displayAddress}
              />
            ));
          }}
          renderOption={(renderOptionProps, option: AddressOptionInterface, { selected }) => {
            const liProps = {
              ...renderOptionProps,
              ...{ key: option?.id || 1 },
            };

            return (
              <li {...liProps}>
                <Checkbox style={{ marginRight: 8 }} checked={selected} />
                {option?.displayAddress}
              </li>
            );
          }}
          onChange={(_, newValue: AddressOptionInterface[]) => {
            onChange?.(newValue);
          }}
        />
      )}
    </>
  );
};
