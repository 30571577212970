import snakeCase from 'lodash/snakeCase'
import { TableStatusComponent } from 'modules/common/components/table-status/table-status.component'
import { DeliveryStatusEnum } from 'modules/deliveries/interfaces/delivery.interface'
import { FunctionComponent } from 'react'

const getChipColor = (status: DeliveryStatusEnum) => {
  switch (snakeCase(status).toUpperCase()) {
    case DeliveryStatusEnum.COMPLETED:
      return 'green'
    case DeliveryStatusEnum.INVOICED:
      return 'lightBlue'
    case DeliveryStatusEnum.IN_PROGRESS:
      return 'blue'
    case DeliveryStatusEnum.CONFIRMED:
      return 'cyan'
  }
}

export const DeliveryStatusComponent: FunctionComponent<{ status: DeliveryStatusEnum; fullWidth?: boolean; stylingProps?: React.CSSProperties, spanStyling?: React.CSSProperties }> = ({ status, fullWidth, stylingProps, spanStyling }) => <TableStatusComponent fullWidth={fullWidth} stylingProps={stylingProps} spanStyling={spanStyling} label={status} color={getChipColor(status)} />
