import { Stack, Typography, TypographyProps } from '@mui/material'
import { Airplane, Boat, IconProps, Package, RadioButton, Train, Truck } from '@phosphor-icons/react'
import { CSSProperties, FunctionComponent } from 'react'

interface IDeliveryTransportModeProps {
  mode: string;
  iconProps?: IconProps;
  typographyProps?: TypographyProps;
  iconStyle?: CSSProperties
}

export const DeliveryTransportMode: FunctionComponent<IDeliveryTransportModeProps> = (props: IDeliveryTransportModeProps) => {
  const { mode = '', iconProps = {}, typographyProps = {}, iconStyle = { minWidth: 20, minHeight: 20 } } = props;
  const Icon = () => {
    if (mode.includes('Road')) {
      return <Truck size={20} color="#6c6a6a" weight="light" {...iconProps} style={iconStyle} />
    } else if (mode.includes('Ocean')) {
      return <Boat size={20} color="#6c6a6a" weight="light"  {...iconProps} style={iconStyle} />
    } else if (mode.includes('Rail')) {
      return <Train size={20} color="#6c6a6a" weight="light"  {...iconProps} style={iconStyle} />
    } else if (mode.includes("Pipeline")) {
      return <RadioButton size={20} color="#6c6a6a" weight="light"  {...iconProps} style={iconStyle} />
    } else if (mode.includes("Air")) {
      return <Airplane size={20} color="#6c6a6a" weight="light"  {...iconProps} style={iconStyle} />
    } else {
      return <Package size={20} color="#6c6a6a" weight="light"  {...iconProps} style={iconStyle} />
    }
  }

  return (
    <Stack direction={'row'} alignItems={'center'} spacing={1}>
      <Icon />
      <Typography color={'inherit'} fontSize={'inherit'} fontFamily={'inherit'} {...typographyProps}>
        {mode}
      </Typography>
    </Stack>
  )
}
