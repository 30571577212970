import { AuthUserInterface } from 'modules/auth';
import { useAuth } from 'modules/auth/hooks';
import { LoadingSkeleton, RoqLink, Table, TableColumnInterface } from 'modules/common/components';
import { EllipseTextComponent } from 'modules/common/components/ellipse-text';
import { TableNoData } from 'modules/common/components/table-no-data/table-no-data.component';
import { useRouter } from 'modules/common/hooks';
import { convertNumberToFormat } from 'modules/common/utils';
import { FormattedDate } from 'modules/date-time/components';
import { useDeliveriesTableStyles } from 'modules/deliveries/components/deliveries-table/deliveries-table.styles';
import { DeliveryStatusComponent } from 'modules/deliveries/components/delivery-status/delivery-status.component';
import { DeliveryTransportMode } from 'modules/deliveries/components/delivery-transport-mode/delivery-transport-mode.component';
import { UseDeliveryHookResponseInterface } from 'modules/deliveries/hooks/use-deliveries.hook';
import { DeliveryInterface } from 'modules/deliveries/interfaces/delivery.interface';
import { FunctionComponent } from 'react'

const getVolumne = (rowData: DeliveryInterface) => {
  const deliveredVolume = convertNumberToFormat(Number(rowData.deliveredVolume || 0))
  return `${deliveredVolume} ${rowData.deliveredVolumeUnit}`
}

const delieveriesTableDefaultColumns = (authUser: AuthUserInterface, classes): TableColumnInterface<DeliveryInterface>[] => [
  {
    title: 'Ship to',
    field: 'shipTo',
    sorting: false,
    cellStyle: {
      width: "10rem",
      maxWidth: "12rem",
      minWidth: "8rem"
    },
    render: (rowData: DeliveryInterface) => (<EllipseTextComponent text={rowData.shipTo.filter((item) => !!item).join(',')} />),
  },
  {
    title: 'Customer PO Number',
    field: 'customerPONumber',
    sorting: false,
    cellStyle: {
      width: "5rem",
      maxWidth: "8rem",
      minWidth: "5rem"
    },
    render: (rowData: DeliveryInterface) => (<EllipseTextComponent textFieldWidth='6rem' text={rowData.customerReference} />),
  },
  {
    title: 'HELM Delivery ID',
    field: 'helmDeliveryId',
    sorting: false,
    cellStyle: {
      width: "6rem",
      maxWidth: "8rem",
      minWidth: "6rem"
    },
    render: (rowData: DeliveryInterface) => (
      <RoqLink
        className={classes.links}
      >
        {rowData.id}
      </RoqLink>)
  },
  {
    title: 'Product',
    field: 'product',
    sorting: false,
    cellStyle: {
      width: "8rem",
      maxWidth: "10rem",
      minWidth: "5rem"
    },
    render: (rowData: DeliveryInterface) => (<EllipseTextComponent text={rowData.productName} textFieldWidth={'100px'} />),
  },
  {
    title: 'Delivered Volume',
    field: 'deliveredVol',
    sorting: false,
    cellStyle: {
      width: "8rem",
      maxWidth: "10rem",
      minWidth: "5rem"
    },
    render: (rowData: DeliveryInterface) => (<EllipseTextComponent text={getVolumne(rowData)} />),
  },
  {
    title: 'Incoterm',
    field: 'incoterm',
    sorting: false,
    cellStyle: {
      width: "4rem",
      maxWidth: "5rem",
      minWidth: "3rem"
    },
    render: (rowData: DeliveryInterface) => (<EllipseTextComponent text={rowData.incoterms} />),
  },
  {
    title: 'Planned Delivery Date (Based on Incoterm)',
    field: 'plannedDeliveryDate',
    sorting: false,
    cellStyle: {
      width: "12rem",
      maxWidth: "13rem",
      minWidth: "12rem"
    },
    render: (rowData: DeliveryInterface) => (rowData.plannedDeliveryDate ? <FormattedDate date={rowData.plannedDeliveryDate} timezone={authUser.timezone} twoLines /> : null),
  },
  {
    title: 'Actual Delivery Date (Based on Incoterm)',
    field: 'actualDeliveryDate',
    sorting: false,
    cellStyle: {
      width: "12rem",
      maxWidth: "13rem",
      minWidth: "12rem"
    },
    render: (rowData: DeliveryInterface) => (rowData.actualDeliveryDate ? <FormattedDate date={rowData.actualDeliveryDate} timezone={authUser.timezone} twoLines /> : null),
  },
  {
    title: 'Transportation Mode',
    field: 'transportationMode',
    sorting: false,
    cellStyle: {
      width: "5rem",
      maxWidth: "6rem",
      minWidth: "4rem"
    },
    render: (rowData: DeliveryInterface) => (<DeliveryTransportMode mode={rowData.transportationModeText}
      typographyProps={{
        fontSize: '13px',
        fontWeight: 400,
        lineHeight: '100%',
        letterSpacing: '0.16px',
      }}
    />),
  },
  {
    title: 'Status',
    field: 'status',
    cellStyle: {
      width: "10rem",
      maxWidth: "11rem",
      minWidth: "10rem"
    },
    sorting: false,
    render: (rowData: DeliveryInterface) => <DeliveryStatusComponent status={rowData.status} />
  },
]

export const DeliveriesTable: FunctionComponent<
  UseDeliveryHookResponseInterface & { filterColumns?: (columns: TableColumnInterface<DeliveryInterface>[]) => TableColumnInterface<DeliveryInterface>[]; paginationEnabled: boolean }
> = ({ data, order, totalCount, pageSize, isLoading, pageNumber, handlePageChange, handleOrderChange, filterColumns, paginationEnabled }) => {
  const { user: authUser } = useAuth();
  const classes = useDeliveriesTableStyles()
  const router = useRouter()

  const updatedColumns = filterColumns ? filterColumns(delieveriesTableDefaultColumns(authUser, classes)) : delieveriesTableDefaultColumns(authUser, classes)
  const tableData: DeliveryInterface[] = data.map((d) => ({ ...d })) || []
  return (
    <>
      {isLoading && <LoadingSkeleton />}
      {!isLoading && (
        <Table
          page={pageNumber}
          onPageChange={handlePageChange}
          onOrderChange={handleOrderChange}
          order={order}
          totalCount={totalCount}
          columns={updatedColumns}
          isLoading={isLoading}
          data={tableData}
          noDataComponent={TableNoData}
          paginationVariant="numbered"
          options={{
            paging: paginationEnabled,
            pageSize,
            showTitle: false,
            search: false,
            sorting: true,
            toolbar: false,
            draggable: false,
            thirdSortClick: false,
          }}
          onRowClick={(_, rowData) =>
            router.push(`/deliveries/${rowData.id}`)
          }
        />
      )}
    </>
  );
}
